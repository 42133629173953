<template>
  <div style="">
    <h1>欢迎使用Erp海外仓系统</h1>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="less"></style>
