import { render, staticRenderFns } from "./onlyReadProductList.vue?vue&type=template&id=9b2356da&scoped=true&"
import script from "./onlyReadProductList.vue?vue&type=script&lang=js&"
export * from "./onlyReadProductList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b2356da",
  null
  
)

export default component.exports